<template>
  <div class="mt-4">
    <b-tabs class="mt-2" content-class="mt-2" justified v-model="tabIndex">
      <b-tab title="Détails du groupe"  >
        <GroupDetails :can-edit="CAN_EDIT" :current-group-id=currentGroupId :current-group=currentGroup @monica-group-updated="updateGroup" />

      </b-tab>

      <b-tab title="Transports">
        <FlightTransportationIndex :can-edit="CAN_EDIT"  :current-group-id=currentGroupId  />

      </b-tab>
      <b-tab title="Hébergement"  >
        <group-accommodations :can-edit="CAN_EDIT"  :current-group-id=currentGroupId   />

      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton } from 'bootstrap-vue'
import GroupDetails from './GroupDetails.vue'
import FlightTransportationIndex from './GroupTransports/FlightTransportationIndex.vue'
import GroupAccommodations from './GroupAccommodations/GroupAccommodations.vue'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {ref, onMounted} from '@vue/composition-api';

import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupDetails,
    FlightTransportationIndex,
    GroupAccommodations,
  },
  props: ['transportOptions','accommodationOptions','currentGroup', 'currentGroupId'],
  data() {
    return {
      currentReservation: {},
      currentPackages: [],
      tabIndex: 0,
      activateTabs: true,
    }
  },
  setup(props, {emit}) {
    const {forceUpdate} = useApplicationContext();

    const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`].role;
    const CAN_EDIT = ref(Roles.roleIsAllowed(CURRENT_ROLE.role, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]));

    const updateGroup = () => {
      emit('monica-group-updated')
    }

    return {
      updateGroup,
      CAN_EDIT
    }

  }

}
</script>

<style>

</style>
